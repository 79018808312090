
import './App.css';
import Soal from './Soal';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <BrowserRouter>
   <Routes>
        <Route path="/soal-page" element={<Soal />} />
        {/* Add other routes here if needed */}
      </Routes>
  </BrowserRouter>
  );
}

export default App;
