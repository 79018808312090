var api;
var apiPayment;
export const storage = 'https://storage.siapptn.com';
// ini harus di automate
// console.log(window.location.hostname )
if(window.location.hostname == 'localhost'){
     // api = 'http://localhost:8443';
     apiPayment = 'https://api.meylendra.com:8080';
     api = 'https://api.meylendra.com:8080';
     // apiPayment = 'https://api.meylendra.com:8080';
}else if(window.location.hostname == "dev.siapptn.com"){
     api = 'https://api.meylendra.com:1998';
     apiPayment = 'https://api.meylendra.com:1998';
}else if(window.location.hostname == "mirror1.siapptn.com"){
     api = 'https://api.meylendra.com:1990';
     apiPayment = 'https://api.meylendra.com:1990';
}else if(window.location.hostname == "mirror2.siapptn.com"){
     api = 'https://api.meylendra.com:1991';
     apiPayment = 'https://api.meylendra.com:1991'
}else if(window.location.hostname == "mirror3.siapptn.com"){
     api = 'https://api.meylendra.com:1992';
     apiPayment = 'https://api.meylendra.com:1992'
}else{
     apiPayment = 'https://api.meylendra.com:8080';
     api = 'https://api.meylendra.com:1998';
}
export const koneksi = `${api}`
export const koneksiPayment = `${apiPayment}`