import Axios from 'axios';
import CryptoJS from 'crypto-js';
import { koneksi } from './config';
export const getHeaderAuth = (token) => {
    const headers = {
        headers: { 
            'Authorization': `Bearer ${token}`,
        }
    }
    return headers
}

export const encryptData = (data) => {
    // Use a secret key for encryption
   if(data){
    const secretKey = '4f8b5f30b2dc4c9e9841c3a4fa1b3f25a6e5b1d7e9c3a8e3f4d6b7c9e4a1b2c3';
    return CryptoJS.AES.encrypt(data, secretKey).toString();
   }else{
    return null
   }
};

  export const  decryptData = (encryptedData) => {
    // Use the same secret key for decryption
   if(encryptedData){
    const secretKey = '4f8b5f30b2dc4c9e9841c3a4fa1b3f25a6e5b1d7e9c3a8e3f4d6b7c9e4a1b2c3';
    const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
    return bytes.toString(CryptoJS.enc.Utf8);
   }else{
    return null
   }
  };

  export const  TimeAgo = (inputDate) => {
    const currentDate = new Date();
    const inputDateTime = new Date(inputDate);
    const diffInMilliseconds = currentDate - inputDateTime;
    const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);
    const diffInWeeks = Math.floor(diffInDays / 7);
    const diffInMonths = Math.floor(diffInDays / 30);
    console.log(diffInDays)
    if (diffInDays === 1) {
      return '1 hari yang lalu';
    } else if (diffInDays > 1 && diffInWeeks === 0) {
      return `${diffInDays} hari yang lalu`;
    } else if (diffInWeeks === 1) {
      return '1 minggu yang lalu';
    } else if (diffInWeeks > 1 && diffInMonths === 0) {
      return `${diffInWeeks} minggu yang lalu`;
    } else if (diffInMonths === 1) {
      return '1 bulan yang lalu';
    } else if (diffInMonths > 1) {
      return `${diffInMonths} bulan yang lalu`;
    }else{
      return 'Baru saja';
    }
};
export const  getDateNow=()=>{

  Axios.post(`${koneksi}/siap-belajar/getdatenow`)
  .then((res)=>{
      var now = new Date(res.data.now);
      return now
     
  })
  
}
export const formatRupiah=(number)=> {
  return number.toLocaleString('id-ID', {
    style: 'currency',
    currency: 'IDR',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });
}
export const premiumTryoutChecker=(status_pendaftaran,id_user,id_tryout)=>{
   const getStatusPremium=(id_user)=>{
      if(id_user != undefined){
      let getData = localStorage.getItem("spm_"+id_user);
      if(getData){
        let getDataParse =  JSON.parse(decryptData(getData));
        if(getDataParse.length>0){
           if(getDataParse[0].id_user == id_user){
            let now =new Date(getDataParse[0].now)
            var startDate = new Date(getDataParse[0].start_date)
            var endDate = new Date(getDataParse[0].end_date)
            
            if(now>= startDate && now<=endDate){
              return true
            }else{
              return false
            }
           }else{
              return false
           }
        }else{
          return false
       }
      }else{
        Axios.post(`${koneksi}/siap-belajar/getsiapbimbelpremium`,{
          id_user,unique:id_user
        }).then((res)=>{
          if(res.data.result.length>0){
              localStorage.setItem("spm_"+id_user,encryptData(JSON.stringify(res.data.result)))
              var now = new Date(res.data.result[0].now);
              var startDate = new Date(res.data.result[0].start_date);
              var endDate = new Date(res.data.result[0].end_date);
              if(now>= startDate && now<=endDate){
                  return true
              }else{
                  return false
              }
          }else{
            localStorage.setItem("spm_"+id_user,encryptData(JSON.stringify(res.data.result)))
            return false
          }
        })
      }
     
      
      }else{
       return false
      }
      
   }
   if(getStatusPremium(id_user)){
      return  [
        {
            "id": Date.now(),
            "id_user": id_user,
            "id_tryout": id_tryout,
            "status_pendaftaran": "verified",
            "jenis_pendaftaran": "siappoin",
            "qty": null,
            "catatan": null,
            "peminatan": "ipc",
            "buka_tryout": "true"
        }
    ]
   }else{
    return status_pendaftaran
   }
}

export const premiumSiambisChecker=()=>{
  let spm = localStorage.getItem("spm_"+id_user())
  if(spm){
    let spmDecrypt = decryptData(spm);
    if(spmDecrypt){
      let spmJson = JSON.parse(spmDecrypt)
      if(spmJson.length>0){
        if(spmJson[0].now < spmJson[0].end_date){
          return true
        }else{
          return false
        }
      }else{
        return false
      }
    }else{
      return false
    }
  }
}

export const siapcasnComponent=()=>{
  let siapcasn = localStorage.getItem("siapcasn");
  console.log(siapcasn)
  if(window.location.hostname === "siapcasn.com" || window.location.hostname === "www.siapcasn.com" || siapcasn){
    console.log("masuk siapcasn")
    return true;
  }
}
export const GreetingComponent=()=> {
  const currentHour = new Date().getHours();
  if (currentHour >= 0 && currentHour < 12) {
    return 'Selamat pagi';
  } else if (currentHour >= 12 && currentHour < 15) {
    return 'Selamat siang';
  } else if (currentHour >= 15 && currentHour < 18) {
    return 'Selamat sore';
  } else {
    return 'Selamat malam';
  }
}
export const username=()=>{
  let userdata = localStorage.getItem("data");
  if(userdata){
   let jsonData=  JSON.parse(userdata)
    if(jsonData[0].username){
      return jsonData[0].username
    }
  }
}
export const data_username=()=>{
  let userdata = localStorage.getItem("data");
  if(userdata){
   let jsonData=  JSON.parse(userdata)
    if(jsonData[0].username){
      return jsonData[0].username
    }
  }
}
export const id_user=()=>{
  let userdata = localStorage.getItem("data");
  if(userdata){
   let jsonData=  JSON.parse(userdata)
    if(jsonData[0].id){
      return jsonData[0].id
    }
  }
}
export const user_id=()=>{
  let userdata = localStorage.getItem("data");
  if(userdata){
   let jsonData=  JSON.parse(userdata)
    if(jsonData[0].id){
      return jsonData[0].id
    }
  }
}
export const token=()=>{
  let token = localStorage.getItem("u-nkt");
  if(token){
   return token
  }
}
export const nama=()=>{
  let userdata = localStorage.getItem("data");
  if(userdata){
   let jsonData=  JSON.parse(userdata)
    if(jsonData[0].nama){
      return jsonData[0].nama
    }
  }
}
export const getCurrentDate = () => {
  const today = new Date();
  const day = today.getDate();
  const month = today.getMonth();
  const year = today.getFullYear();

  const months = [
    'Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni',
    'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'
  ];

  return `${day} ${months[month]} ${year}`;
};

export const calculateDays=(currentDate)=> {
  const startDate = new Date(2024, 8, 1); // 1st August 2024
  const endDate = new Date(2025, 3, 30);  // 30th April 2025

  const dayNumber = Math.floor((currentDate - startDate) / (1000 * 60 * 60 * 24)) + 1; // Calculate day number
  const daysRemaining = Math.floor((endDate - currentDate) / (1000 * 60 * 60 * 24)); // Calculate days remaining

  return {
      dayNumber,
      daysRemaining
  };
}
export const calculateWeeks=(currentDate)=> {
  const startDate = new Date(2024, 8, 1); 
  const endDate =  new Date(2025, 5, 30);
  const dayNumber = Math.floor((currentDate - startDate) / (1000 * 60 * 60 * 24)); // Calculate day number
  const weekNumber = Math.floor(dayNumber / 7) + 1; // Calculate week number

  const totalDays = Math.floor((endDate - startDate) / (1000 * 60 * 60 * 24)); // Total days in the period
  const totalWeeks = Math.floor(totalDays / 7); // Total weeks in the period
  const weekRemaining = totalWeeks - weekNumber; // Calculate weeks remaining

  return {
      weekNumber,
      weekRemaining
  };
}
export const isValidUsername=(username)=> {
  // Cek apakah username mengandung spasi
  if (/\s/.test(username)) {
    return false; // Username tidak valid jika ada spasi
  }
  
  return true; // Username valid jika tidak ada spasi
}