import Axios from 'axios';
import Swal from 'sweetalert2';
import { koneksi } from '../helper/config';
import { user_id } from '../helper/function';

function SimpanButtonIritDatabase({ id_tryout, id_mapel, kode,setLoadingSimpanJawaban }) {

console.log("masuk simpan")
  const handleSimpan = () => {
    console.log("masuk hand;e")
    setLoadingSimpanJawaban(true);
    const id_user = user_id();

    // Tentukan peminatan berdasarkan kode
    let peminatan = "";
    if (kode === "S001") peminatan = "Saintek";
    else if (kode === "S002") peminatan = "Soshum";
    else if (kode === "S003") peminatan = "ipc";

    // Fungsi untuk melanjutkan ke menu berikutnya
    const nextStep = () => {
        SelanjutnyaMenu();
    };
    const SelanjutnyaMenu=()=>{
        const message = { type: 'siapptn', func: 'navBack',id_tryout,kode };
        window.parent.postMessage(message, '*'); // '*' artinya pesan bisa dikirim ke semua origin
    }
    const dataStorage = localStorage.getItem(`soal_${id_mapel}_${id_tryout}_${peminatan}`)
    if(dataStorage){
        const dataJawaban = JSON.parse(dataStorage);
     
        dataJawaban.sort((a, b) => parseFloat(a.no_soal) - parseFloat(b.no_soal));
  
      Axios.post(`${koneksi}/tryoutsbmptn/getkode`, { id_tryout, id_mapel })
      .then((res) => {
          if (res.data.kjwe) {
          const kunci = res.data.kjwe;
          const allData = [];
  
          dataJawaban.forEach((val) => {
              if (val.jawaban !== undefined) {
                  if(id_mapel !== "69"){
                      const status_soal = kunci[val.no_soal - 1].tipe_soal !== 2 
                      ? val.jawaban === kunci[val.no_soal - 1].kunci ? 'benar' : 'salah' 
                      : bandingkanJawaban(val.jawaban, kunci[val.no_soal - 1].kunci);
                      allData.push({
                          id_user: val.id_user,
                          id_mapel: val.id_mapel,
                          no_soal: val.no_soal,
                          jawaban: val.jawaban,
                          id_tryout: val.id_tryout,
                          peminatan,
                          status: status_soal
                      });
                  }else{
                      var dataJawabanUrutan = res.data.kyjwe2
                      var jawabanSoal = JSON.parse(dataJawabanUrutan[val.no_soal-1].jawaban_urutan)
                      var nilai;
                      for (var i in jawabanSoal) {
                          if (jawabanSoal[i].value === val.jawaban) {
                              nilai = jawabanSoal[i].point;
                              break; // Hentikan iterasi setelah ditemukan objek dengan nilai "value" yang cocok
                          }
                      }
                      const status_soal = nilai
                      allData.push({
                          id_user: val.id_user,
                          id_mapel: val.id_mapel,
                          no_soal: val.no_soal,
                          jawaban: val.jawaban,
                          id_tryout: val.id_tryout,
                          peminatan,
                          status: status_soal,
                          point:nilai
                      });
                  }
             
              }
          });
       const body = {
          jawaban_user_permapel: JSON.stringify(allData),
          unique: `${id_mapel}_${id_tryout}_${id_user}`,
          id_user,
          id_tryout,
          id_mapel,
          peminatan,
          benar: id_mapel !== "69"?allData.filter((item) => item.status === "benar").length:allData.reduce((total, item) => total + parseInt(item.point), 0),
          salah: allData.filter((item) => item.status === "salah").length,
          kosong: allData.filter((item) => item.status === "kosong").length,
      }
                Axios.post(`${koneksi}/tryoutsbmptn/postjawabanbulktosbmptnjilid3`, body).then(() => {
                    Swal.fire({
                        title: 'Jawaban tersimpan',
                        text: 'Semua jawabanmu pada sub test ini telah sukses tersimpan',
                        icon: 'success',
                        position: 'top',
                        backdrop: false // Menghilangkan backdrop
                    }).then(() => {
                        setLoadingSimpanJawaban(false);
                        localStorage.removeItem(`activePage_${id_mapel}_${id_tryout}`)
                        nextStep();
                    });
                    localStorage.removeItem(`soal_${id_mapel}_${id_tryout}_${peminatan}`);
                    localStorage.removeItem(`soal_terakhir_${id_mapel}_${id_tryout}_${peminatan}`);
                }).catch((err) => {
                    setLoadingSimpanJawaban(false);
                    alert("Proses simpan jawaban otomatis gagal! Pastikan koneksimu stabil dan coba kembali.");
                    console.error(err);
                });
          }
      })
      .catch((err) => {
          setLoadingSimpanJawaban(false);
          console.error(err);
      });
    }else{
        alert("belum ada jawaban yang bisa di simpan")
    }
      
    
  };

  const bandingkanJawaban = (jawaban, kunci) => {
    const jawabanObj = JSON.parse(jawaban);
    const kunciObj = JSON.parse(kunci);

    if (!jawabanObj || Object.keys(jawabanObj).length === 0) {
      return "kosong";
    }
    for (let key in jawabanObj) {
      if (jawabanObj[key] !== kunciObj[key]) {
        return "salah";
      }
    }
    return "benar";
  };

  return handleSimpan();
}

export default SimpanButtonIritDatabase;
