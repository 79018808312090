import React, { useState, useEffect } from 'react';
import { decryptData } from './helper/function';
import Swal from 'sweetalert2';
import Axios from 'axios';
import { Markup } from 'interweave';
import { MathJax, MathJaxContext } from 'better-react-mathjax';
import { koneksi } from './helper/config';
import { useLocation } from 'react-router-dom';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import SimpanButtonIritDatabase from './service/SimpanJawabanTryout';


const SoalV2 = (props) => {
  const [soaldetail, setSoaldetail] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadHalaman, setLoadHalaman] = useState(false);
  const [totalItem, setTotalItem] = useState(0);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const [tampilPerPage] = useState(1); // Fixed value
  const [activePage, setActivePage] = useState(1);
  const [kunciMajemuk, setKunciMajemuk] = useState({
    pilihan_a: null,
    pilihan_b: null,
    pilihan_c: null,
    pilihan_d: null,
  });
  const [loadingSimpanJawaban, setLoadingSimpanJawaban] = useState(false);
  const routerLocation = useLocation();

  useEffect(() => {
    getActivePage()
    getDetailSoal();
    getDataFromParent()
  }, []);

const getDataFromParent=()=>{
    const messageHandler = (event) => {
    // Anda bisa menambahkan validasi untuk event.origin di sini jika perlu
      // Set data yang diterima dari parent
      console.log(event.data)
      if(event.data.type === "siapptn"){
        console.log(event.data)
      }
    };

    // Menambahkan listener untuk event message
    window.addEventListener('message', messageHandler);

    // Membersihkan listener saat komponen unmount
    return () => {
        window.removeEventListener('message', messageHandler);
    };
 }
  const getActivePage=()=>{
    const storedPage = parseInt(localStorage.getItem(`activePage_${getQueryParams().id_mapel}_${getQueryParams().id_tryout}`), 10);
    if (storedPage) setActivePage(storedPage);
  }

  const getQueryParams = () => {
    const params = new URLSearchParams(routerLocation.search);
    return {
      mapel: params.get('mapel'),
      id_tryout: params.get('id_tryout'),
      id_mapel: params.get('id_mapel'),
      kode: params.get('kode'),
      userId: params.get('id_user'),
    };
  };
  const getDetailSoal =()=> {
    setLoadHalaman(true);
    const { mapel, id_tryout, id_mapel, kode,userId } = getQueryParams();
    const id_user = decryptData(userId.replace(/ /g, '+'));
    const dataStorageSoal = localStorage.getItem(`gsam_${id_tryout}`);
    const parseSoal = JSON.parse(decryptData(dataStorageSoal));

    if (dataStorageSoal && parseSoal && parseSoal.length > 0) {
      const dataFilter = parseSoal.filter(item => item.id_mapel === parseInt(id_mapel));
      const dataSoalFilter = dataFilter.sort((a, b) => a.no_soal - b.no_soal);

      setSoaldetail(dataSoalFilter);
      setTotalItem(dataSoalFilter.length);
      setTotalPage(Math.ceil(dataSoalFilter.length / tampilPerPage));
      getJawabanUser(dataSoalFilter[0].id_mapel,dataSoalFilter);

      const peminatan = kode === "S001" ? "Saintek" : kode === "S002" ? "Soshum" : "ipc";
      const no_soal = localStorage.getItem(`soal_terakhir_${id_mapel}_${id_tryout}_${peminatan}`);
      if (no_soal) setActivePage(parseInt(no_soal));
    } else if (id_user) {
      Axios.post(`${koneksi}/tryoutsbmptn/getsoalbytoandmapel`, { mapel, id_tryout, id_user, unique: `${id_mapel}_${id_tryout}` })
      .then((res)=>{
        setSoaldetail(res.data);
        setTotalItem(res.data.length);
        setTotalPage(Math.ceil(res.data.length / tampilPerPage));
        getJawabanUser(res.data[0].id_mapel,res.data);
      }).catch((err)=>{
        setLoadHalaman(false);
      })
    }
    setLoadHalaman(false);
  };

  const setJawaban = (event) => {
    const {userId } = getQueryParams();
    const id_user = decryptData(userId.replace(/ /g, '+'));
    if (id_user) {
      const { id_tryout, kode, id_mapel } = getQueryParams();
      const no_soal = activePage;
      const jawaban = event.target.value;
      const peminatan = kode === "S001" ? "Saintek" : kode === "S002" ? "Soshum" : "ipc";

      const dataBaru = [...soaldetail];
      dataBaru[no_soal - 1].jawaban = jawaban;
      setSoaldetail(dataBaru);

      const dataJawaban = JSON.parse(localStorage.getItem(`soal_${id_mapel}_${id_tryout}_${peminatan}`)) || [];
      const existingJawaban = dataJawaban.find(jwb => jwb.no_soal === no_soal);

      if (existingJawaban) {
        existingJawaban.jawaban = jawaban;
      } else {
        dataJawaban.push({ id_user, id_mapel, id_tryout, no_soal, jawaban, peminatan });
      }

      localStorage.setItem(`soal_${id_mapel}_${id_tryout}_${peminatan}`, JSON.stringify(dataJawaban));
      localStorage.setItem(`activePage_${id_mapel}_${id_tryout}`, activePage);
    } else {
      Swal.fire('Oops...', 'Maaf, masa login Anda telah habis. Silakan login kembali.', 'error')
        .then(() => window.location = "/masuk");
    }
  };

  const getJawabanUser = (id_mapel,data) => {
    const {userId } = getQueryParams();
    const id_user = decryptData(userId.replace(/ /g, '+'));
    const { id_tryout, kode } =getQueryParams();
    const peminatan = kode === "S001" ? "Saintek" : kode === "S002" ? "Soshum" : "ipc";
    const dataJawaban = JSON.parse(localStorage.getItem(`soal_${id_mapel}_${id_tryout}_${peminatan}`));
    if (dataJawaban && data.length>0) {
      const dataBaru = [...data];
      dataJawaban.forEach(jawaban => {
        const soal = dataBaru.find(item => item.no_soal === jawaban.no_soal);
        if (soal) soal.jawaban = jawaban.jawaban;
      });
      setSoaldetail(dataBaru)
      setLoadHalaman(false)
    } else {
      var unique = `${id_mapel}_${id_tryout}_${id_user}`
      Axios.post(`${koneksi}/tryoutsbmptn/getjawabanbytoandmapelv2`, { id_user, id_mapel, id_tryout, peminatan,unique })
        .then(res => {
          if (res.data.length > 0) {
            localStorage.setItem(`soal_${id_mapel}_${id_tryout}_${peminatan}`, res.data[0].jawaban_user_permapel);
            const dataBaru = [...data];
            let jawabanUser = JSON.parse(res.data[0].jawaban_user_permapel);
            jawabanUser.forEach(jawaban => {
              const soal = dataBaru.find(item => item.no_soal === jawaban.no_soal);
              if (soal) soal.jawaban = jawaban.jawaban;
            });
            setSoaldetail(dataBaru)
            setLoadHalaman(false)
          }
        })
        .catch(err => {
            setLoadHalaman(false)
        });
    }
  };

  const nextPage = () => {
    setActivePage(prevActivePage => {
      const newPage = Math.min(prevActivePage + 1, totalPage);
      localStorage.setItem(`activePage_${getQueryParams().id_mapel}_${getQueryParams().id_tryout}`, newPage);
      getJawabanUser(getQueryParams().id_mapel,soaldetail);
      return newPage;
    });
  };

  const prevPage = () => {
    setActivePage(prevActivePage => {
      const newPage = Math.max(prevActivePage - 1, 1);
      localStorage.setItem(`activePage_${getQueryParams().id_mapel}_${getQueryParams().id_tryout}`, newPage);
      getJawabanUser(getQueryParams().id_mapel,soaldetail);
      return newPage;
    });
  };

  const saveAnswers = () => {
    console.log("masuk")
    const { id_tryout, kode,id_mapel } = getQueryParams();
    SimpanButtonIritDatabase({id_tryout,id_mapel,kode,setLoadingSimpanJawaban})
  };

  const containsLatex = (input) => {
    const latexPattern = /\\\(|\\\[|\\\)|\\\]/;
    return latexPattern.test(input);
  };

  const latexFixer = (data) => {
    if (data) {
      return data.replace(/\\\\/g, "\\").replace(/\\n/g, "\n");
    }
  };

  const renderItem = (data) => {
    if (containsLatex(data)) {
      return (
          <MathJax style={{ display: 'inline-flex', verticalAlign: 'middle' }}>
            <Markup content={latexFixer(data)} tagName="span" />
          </MathJax>
      );
    } else {
      return <Markup content={data} tagName="span" />;
    }
  };
  const selectSoal = (noSoal) => {
    setActivePage(noSoal);
    setModalIsOpen(false); // Tutup modal setelah memilih soal
    localStorage.setItem(`activePage_${getQueryParams().id_mapel}_${getQueryParams().id_tryout}`, noSoal);
  };
  const toggleModal = () => {
    setModalIsOpen(!modalIsOpen);
  };
  const handleRadioChange = (item, option, answer) => {
    
    setKunciMajemuk((prevJawaban) => {
      const newJawaban = {
        ...prevJawaban,
        [option]: prevJawaban[option] === answer ? item : answer // Toggle nilai dari checkbox
      };
      // Ini akan menampilkan nilai baru dari kunciMajemuk
      const { userId } = getQueryParams();
      const id_user = decryptData(userId.replace(/ /g, '+'));
      const { id_tryout, kode, id_mapel } = getQueryParams();
      
      const no_soal = activePage;
      const jawaban = JSON.stringify(newJawaban);
      const peminatan = kode === "S001" ? "Saintek" : kode === "S002" ? "Soshum" : "ipc";

      const dataBaru = [...soaldetail];
     if(item){
      var merged = Object.assign({}, item, newJawaban);

        // Memastikan untuk tidak menimpa nilai non-null
      for (let key in merged) {
        if (merged[key] === null) {
          merged[key] = item[key];
        }
      }
     }

      console.log(merged);
      dataBaru[no_soal - 1].jawaban = JSON.stringify(merged);
      setSoaldetail(dataBaru);
      console.log(soaldetail)

      const dataJawaban = JSON.parse(localStorage.getItem(`soal_${id_mapel}_${id_tryout}_${peminatan}`)) || [];
      const existingJawaban = dataJawaban.find(jwb => jwb.no_soal === no_soal);

      if (existingJawaban) {
        existingJawaban.jawaban = merged?JSON.stringify(merged):jawaban;
      } else {
        dataJawaban.push({ id_user, id_mapel, id_tryout, no_soal, jawaban:merged?JSON.stringify(merged):jawaban, peminatan });
      }

      localStorage.setItem(`soal_${id_mapel}_${id_tryout}_${peminatan}`, JSON.stringify(dataJawaban));
      localStorage.setItem(`activePage_${id_mapel}_${id_tryout}`, activePage);
      return newJawaban;
    });
  };
  // const handleRadioChange = (item, option, answer) => {
  //   console.log(item);
    
  //   setKunciMajemuk(prevKunciMajemuk => {
  //     const updatedKunciMajemuk = {
  //       ...prevKunciMajemuk,
  //       [option]: answer,
  //     };

  //     // Simpan nilai ke localStorage
  //     const propertiUrutan = ["pilihan_a", "pilihan_b", "pilihan_c", "pilihan_d"];
  //     const newKunciMajemuk = {};

  //     propertiUrutan.forEach(properti => {
  //       newKunciMajemuk[properti] = option === properti ? answer : updatedKunciMajemuk[properti];
  //     });

  //     let peminatan;
  //     if (props.kode === "S001") {
  //       peminatan = "Saintek";
  //     } else if (props.kode === "S002") {
  //       peminatan = "Soshum";
  //     } else if (props.kode === "S003") {
  //       peminatan = "ipc";
  //     }

  //     const storageKey = `soal_${item.id_mapel}_${item.id_tryout}_${peminatan}`;
  //     const storedData = JSON.parse(localStorage.getItem(storageKey)) || [];
      
  //     // Temukan atau tambahkan item sesuai nomor soal
  //     const existingItemIndex = storedData.findIndex(dataItem => dataItem.no_soal === item.no_soal);
      
  //     if (existingItemIndex !== -1) {
  //       // Item sudah ada, update jawaban di dalam kunciMajemuk
  //       storedData[existingItemIndex].jawaban = JSON.stringify(newKunciMajemuk);
  //     } else {
  //       // Item belum ada, tambahkan item baru
  //       storedData.push({
  //         id_user: item.id_user, // Ganti dengan id_user yang sesuai
  //         id_mapel: item.id_mapel,
  //         id_tryout: item.id_tryout,
  //         no_soal: item.no_soal,
  //         jawaban: JSON.stringify(newKunciMajemuk), // Inisialisasi objek jawaban dengan satu kunci dan nilai
  //         peminatan: item.peminatan,
  //       });
  //     }
      
  //     console.log(storedData);
  //     // Simpan data ke localStorage
  //     localStorage.setItem(storageKey, JSON.stringify(storedData));
      
  //     return updatedKunciMajemuk; // Kembalikan state yang diperbarui
  //   });
  //   console.log(kunciMajemuk)
  // };
  const currentQuestion = soaldetail[activePage - 1];
  
  const optionSoal=()=>{
   if(currentQuestion.tipe_soal === 1){
   return(
    <div className="soal-options" style={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
    {['a', 'b', 'c', 'd', 'e'].map((option, index) => (
      currentQuestion[option] ? ( // Menampilkan opsi hanya jika ada isinya
        <label key={option} style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '8px', cursor: "pointer" }}>
          <input
            type="radio"
            name="jawaban"
            id={option}
            key={`${option}_${currentQuestion.no_soal}_${currentQuestion.id_mapel}_${currentQuestion.id_tryout}_${JSON.stringify(soaldetail)}`}
            value={option}
            style={{ marginRight: '10px', transform: 'scale(1.3)', marginTop: "6px" }}
            defaultChecked={currentQuestion.jawaban === option}
            onChange={setJawaban}
          />
          <b>{String.fromCharCode(65 + index)}</b>. {renderItem(currentQuestion[option])}
        </label>
      ) : '' // Jika tidak ada isinya, tidak menampilkan opsi
    ))}
  </div>
   )
   }else if(currentQuestion.tipe_soal === 2){
    return (
      <table className="table table-bordered table-siap" style={{ marginTop: '10px' }} key={`soal_${currentQuestion.no_soal}_${currentQuestion.id_mapel}_${currentQuestion.id_tryout}`}>
         <thead>
            <tr >
              <th style={{ textAlign: 'center', verticalAlign: 'middle' }}>Pernyataan</th>
              <th style={{ textAlign: 'center', verticalAlign: 'middle' }} >
                {currentQuestion.tipe_pernyataan? JSON.parse(currentQuestion.tipe_pernyataan).a : "Benar"}
              </th>
              <th style={{ textAlign: 'center', verticalAlign: 'middle' }}>
              {currentQuestion.tipe_pernyataan? JSON.parse(currentQuestion.tipe_pernyataan).b : "Salah"}
              </th>
            </tr>
        </thead>
        <tbody>
        {['a', 'b', 'c', 'd'].map((option, index) => (
          currentQuestion[option] ? ( // Menampilkan opsi hanya jika ada isinya
           <tr key={`jawaban_${option}_${currentQuestion.no_soal}_${currentQuestion.id_mapel}_${currentQuestion.id_tryout}`}>
              <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
              {renderItem(currentQuestion[option])}
              </td>
              <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                <input
                    type="radio"
                    id={`pilihan_${option}_${currentQuestion.no_soal}_${currentQuestion.id_mapel}_${currentQuestion.id_tryout}_1`}
                    name={`pilihan_${option}_${currentQuestion.no_soal}_${currentQuestion.id_mapel}_${currentQuestion.id_tryout}`}
                    key={`1_${option}_${currentQuestion.no_soal}_${currentQuestion.id_mapel}_${currentQuestion.id_tryout}_${JSON.stringify(soaldetail)}`}
                    style={{ marginRight: '10px', transform: 'scale(2)', marginTop: "6px" }}
                    defaultChecked={currentQuestion.jawaban? JSON.parse(currentQuestion.jawaban)[`pilihan_${option}`] === 'benar':false}
                    onChange={() => handleRadioChange(currentQuestion.jawaban?JSON.parse(currentQuestion.jawaban):'', `pilihan_${option}`, 'benar')} // Ubah sesuai dengan format jawaban
                  />
            </td>
            <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
              <input
                  type="radio"
                  id={`pilihan_${option}_${currentQuestion.no_soal}_${currentQuestion.id_mapel}_${currentQuestion.id_tryout}_2`}
                  name={`pilihan_${option}_${currentQuestion.no_soal}_${currentQuestion.id_mapel}_${currentQuestion.id_tryout}`}
                  key={`2_${option}_${currentQuestion.no_soal}_${currentQuestion.id_mapel}_${currentQuestion.id_tryout}_${JSON.stringify(soaldetail)}`}
                  style={{ marginRight: '10px', transform: 'scale(2)', marginTop: "6px" }}
                  defaultChecked={currentQuestion.jawaban? JSON.parse(currentQuestion.jawaban)[`pilihan_${option}`] === 'salah':false}
                  onChange={() =>  handleRadioChange(currentQuestion.jawaban?JSON.parse(currentQuestion.jawaban):'', `pilihan_${option}`, 'salah')} // Ubah sesuai dengan format jawaban
                />
            </td>
           </tr>
          ) : null 
        ))}
        </tbody>
      </table>
    );
   }else if(currentQuestion.tipe_soal === 3){
    return <input type='number' className='text-choice'  
    placeholder='Isi jawaban mu disini'
    id={'type_text'+currentQuestion.no_soal+"_"+currentQuestion.id_mapel+"_"+currentQuestion.id_tryout} 
    key={'type_text'+currentQuestion.no_soal+"_"+currentQuestion.id_mapel+"_"+currentQuestion.id_tryout} 
    name={'jawaban_'+currentQuestion.no_soal+"_"+currentQuestion.id_mapel+"_"+currentQuestion.id_tryout} 
    defaultValue={currentQuestion.jawaban} 
    onChange={(e)=>setJawaban(e)}/>
  }
  }
  return (
    <div className="soal-container">
    <div className='d-flex justify-content-end mb-4'>
        <Button color="primary" className='btn-siapptn' onClick={toggleModal}>
            Lihat Semua Soal
        </Button>
    </div>
  <MathJaxContext>
    {loadHalaman && <p className="soal-loading">Loading halaman...</p>}
    {!loadHalaman && currentQuestion && (
      <div>
        {currentQuestion.soal_cerita && (
         <div className='border p-3 mb-3 br-3' key={'soal_cerita_'+currentQuestion.no_soal+'_'+currentQuestion.id_mapel+'_'+currentQuestion.id_tryout}>
             <p className="soal-cerita">{renderItem(currentQuestion.soal_cerita)}</p>
        </div>
        )}
        <h3 className="soal-question" key={'soal_pertanyaan_'+currentQuestion.no_soal+'_'+currentQuestion.id_mapel+'_'+currentQuestion.id_tryout}>
          <span className="soal-number">{currentQuestion.no_soal}.</span> {renderItem(currentQuestion.soal)}
        </h3>
        
        {optionSoal()}
        <div className="navigation-buttons">
          <button onClick={prevPage}  disabled={activePage === 1}>Sebelumnya</button>
          {
                activePage !== totalPage ? (
                    <button onClick={nextPage} disabled={activePage === totalPage} className='btn-siapptn'>
                        Selanjutnya
                    </button>
                ) : (
                    loadingSimpanJawaban ? (
                        <button className='btn-siapptn' disabled>
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            &nbsp; Menyimpan...
                        </button>
                    ) : (
                        <button onClick={saveAnswers} className='btn-siapptn'>
                            Simpan Jawaban
                        </button>
                    )
                )
            }
        </div>
        <div className="save-button">
          {
             loadingSimpanJawaban ? (
              <button  disabled>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  &nbsp; Menyimpan...
              </button>
          ) : (
              <button onClick={saveAnswers} >
                  Simpan Jawaban
              </button>
          )
          }
        </div>
      
      </div>
    )}
    <Modal isOpen={modalIsOpen} toggle={toggleModal}  backdrop={false}>
        <ModalHeader toggle={toggleModal}>Pilih Soal</ModalHeader>
        <ModalBody>
          {soaldetail.map((soal, index) => (
            <Button
              key={index}
              color={activePage=== index+1? "primary" : soal.jawaban ? "success" : "info"}
              onClick={() => selectSoal(index + 1)}
              className="m-1"
            >
              {index + 1}
            </Button>
          ))}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleModal}>Tutup</Button>
        </ModalFooter>
      </Modal>
  </MathJaxContext>
</div>

  );
};

export default SoalV2;
